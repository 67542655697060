<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Personal de servicio"></header-title>
      <p class="grey--text text--darken-1">
        Servicios Multiples, Portero/a, Limpieza, Auxiliar de Cocina.
      </p>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Institución"
            :items="institutions"
            v-model="options.institution_id"
            item-text="nombre"
            item-value="codigo"
            @change="getAdministrativeStaff"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="error"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportServiceStaff"
                :disabled="serviceStaffs.length == 0"
              >
                PDF
              </v-btn>
            </template>
            <span>Personal servicio</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                color="info"
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="reportServiceStaffGeneral"
              >
                PDF
              </v-btn>
            </template>
            <span>Personal de servicio en general</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="serviceStaffs"
        :search="options.search"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, 150, -1],
        }"
        calculate-widths
        item-key="name"
        class="elevation-2"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{ serviceStaffs.indexOf(item) + 1 }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  type="button"
                  color="info"
                  :loading="btn_loading"
                  @click="showDetail(item.codigo)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-view-list-outline</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="detaildialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Detalle</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <h4 class="teal--text">Datos personales</h4>
            <personal-information :person="serviceStaff" />
            <h4 class="teal--text">Formación y cargo</h4>
            <v-container grid-list-md>
              <v-row dense>
                <v-col cols="12">
                  <b>Profesión: </b>
                  {{ serviceStaff.profesion? serviceStaff.nombre_profesion: 'sin dato' }}
                </v-col>
                <v-col cols="12">
                  <b>Tipo de designación: </b>
                  {{
                    serviceStaff.tipo_designacion ? serviceStaff.tipo_designacion : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Tipo de cargo: </b>
                  {{
                    serviceStaff.tipo_cargo
                      ? serviceStaff.tipo_cargo
                      : "sin dato"
                  }}
                </v-col>
                <v-col cols="12">
                  <b>Cargo: </b>
                  {{ serviceStaff.cargo ? serviceStaff.cargo : "sin dato" }}
                </v-col>
              </v-row>
            </v-container>
            <h4 class="teal--text">Ubicación</h4>
            <person-location-data :person="serviceStaff" />
            
            <h4 class="teal--text">Contacto de emergencia</h4>
            <personal-contacto-emergencia :person="serviceStaff" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click.native="detaildialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import PersonalInformation from "@/components/PersonalInformation";
import PersonLocationData from "@/components/PersonLocationData.vue";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import PDFService from "../../utils/pdf";
import Service from "../../services/request";
import { mapActions } from "vuex";

import PersonalContactoEmergencia from "@/components/PersonalContactoEmergencia";

export default {
  name: "staff",
  components: {
    HeaderTitle,
    PersonalInformation,
    PersonLocationData,
    PersonalContactoEmergencia,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      pdf_loading: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Código ", value: "codigo", sortable: false },
        { text: "Nombres y Apellidos ", value: "nombre" },
        { text: "Cédula Identidad ", value: "cedula" },
        { text: "RDA ", value: "rda", sortable: false },
        { text: "Cargo ", value: "cargo" },
        { text: "Teléfono", value: "telefono", sortable: false },
        { text: "Acciones ", value: "actions", sortable: false },
      ],
      institutions: [],
      serviceStaffs: [],
      serviceStaff: {
        canton: "",
        cedula: "",
        cod_pers: "",
        correo: "",
        departamento: "",
        domicilio: "",
        estado: null,
        estado_civil: "",
        expedido: "",
        fecha_etado: "",
        fecha_nacimiento: "",
        genero: "",
        grupo_sangui: "",
        localidad: "",
        municipio: "",
        nombre: "",
        profesion: "",
        provincia: "",
        rda: "",
        telefono: "",
        modalidad: "",
      },
      options: {
        search: "",
        institution_id: "",
      },
      detaildialog: false,
      user: null,
      serve: "",
    };
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    this.user = Storage.getUser();
    if (this.user && this.user.rid == 1) {
      this.getInstitutionParameter();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getInstitutionParameter() {
      Service.svcInstitutionParameter("get")
        .then((response) => {
          this.institutions = response.data;
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getAdministrativeStaff() {
      this.loading = true;
      Service.svcServiceStaff("post", this.options)
        .then((response) => {
          this.loading = false;
          this.serviceStaffs = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDetail(person_code) {
      this.btn_loading = true;
      Service.svcPersonById("get", { person_code: person_code })
        .then((response) => {
          this.btn_loading = false;
          this.serviceStaff = response.data;
          this.detaildialog = true;
        })
        .catch((error) => {
          this.btn_loading = false;
          console.error("No cargo el registro", error);
        });
    },
    clearFilter() {
      this.options.institution_id = "";
      this.serviceStaffs = [];
    },
    reportServiceStaff() {
      this.pdf_loading = false;
      const institution = this.institutions.find(
        (item) => item.codigo === this.options.institution_id
      );
      PDFService.pdfStaff("DE SERVICIO", institution, this.serviceStaffs);
    },
    reportServiceStaffGeneral() {
      this.pdf_loading = false;
      Service.svcServiceStaffGeneral("get")
        .then((response) => {
          this.pdf_loading = false;
          PDFService.pdfStaffGeneral("DE SERVICIO", response.data);
        })
        .catch(() => {
          this.pdf_loading = false;
        });
    },
  },
};
</script>
